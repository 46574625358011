import React from 'react'
import { graphql } from 'gatsby'
import { Container } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01'
import Appointment from '@solid-ui-blocks/Modal/Block03'
import Header from '@solid-ui-blocks/Header/Block01'
import Features from '@solid-ui-blocks/Features/Block06'
import WideCTA from '@solid-ui-blocks/Hero/Block03'
import Faq from '@solid-ui-blocks/Faq/Block02'
import Footer from '@solid-ui-blocks/Footer/Block01'
import Content from '@solid-ui-blocks/Content/Block01'
import Portfolio from '@solid-ui-blocks/Features/Portfolio'
import Ansprechpartner from '@solid-ui-blocks/Hero/AnsprechpartnerBlock01'
import Clients from '@solid-ui-blocks/FeaturesWithPhoto/Block05'
import Unternehmen from '@solid-ui-blocks/Companies/Block01'
import Motto from '@solid-ui-blocks/Content/Block01'

import { normalizeBlockContentNodes } from '@blocks-helpers'
import styles from './_styles'


const Services01 = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  const schema =
  {
    "@context": "https://schema.org",
    "@graph":
      [
        {
          "@type":"Product",
          "name":"Bitconcepts",
          "description":"Website Design Oldenburg",
          "aggregateRating":{"@type":"AggregateRating","reviewCount":9,"ratingValue":5,"bestRating":5,"worstRating":1},
          "image":"https://bitconcepts.de/static/82275983ca2245e1976573fbbe2f867d/a067c/logo.webp"
        }		
      ]
  }

  return (
    <Layout {...props}>
      <Seo
        title='Webdesign für Oldenburg | Bitconcepts'
        description='Bitconcepts - Agentur für Digitalmarketing, Suchmaschinenwerbung, Webdesign, Social Media und mehr. Erfahre mehr!'
        schemaMarkup={schema}
      />
      {/* Modals */}
      <ModalWithTabs content={content['contact']} />
      <Appointment />
      {/* Blocks */}
      <Header content={content['header']} menuJustify='space-between' />
      <Container variant='full' sx={styles.heroContainer}>
        <Features content={content['hero']} />
        <Divider space='4' />
        <Divider space='4' />
      </Container>
      <Divider space='1' />
      <Container variant='narrow' >
        <Motto content={content['motto']} />
      </Container>
      <Divider space='3' />
      <Container variant='full' sx={styles.maintext}>
        <Clients content={content['bigtexttwo']} reverse />
        <Divider space='4' />
        <Clients content={content['bigtextone']} />
      </Container>
      <Container variant='full' sx={styles.zwischentext}>
        <Content content={content['smalltext']} />
      </Container>
      <Divider space='5' />
      <Portfolio content={content['portfolio']} />
      <Divider space='5' />
      <Unternehmen content={content['unternehmen']} />
      <Divider space='5' />
      <Faq content={content['faq']} />
      <Divider space='5' />
      <Container variant='full' sx={styles.ansprechpartner}>
        <Ansprechpartner content={content['ansprechpartner']} reverse />
      </Container>
      <WideCTA content={content['bottomcta']} />
      <Footer content={content['footer']} />
    </Layout>
  )
}

export const query = graphql`
  query webdesignoldenburg {
    allBlockContent(
      filter: { page: { in: ["site/web-design-oldenburg", "shared"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`

export default Services01
